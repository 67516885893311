<template>
  <div class='app-container'>
    <!-- <el-alert title="创建策略需要先选择相应的账号类型，再对其类型创建相应的权限策略" type="info" :closable='false' show-icon>
    </el-alert> -->

<!--    <el-tabs v-model="activeName" @tab-click="tabClickHandle" class="el-tabs_self">-->
<!--      &lt;!&ndash; <el-tab-pane v-for="item in roles" :key="item.roleId" :label="item.description" :name="item.description">-->
<!--          <create-auth :roleId='item.roleId'></create-auth>-->
<!--      </el-tab-pane> &ndash;&gt;-->
<!--      <el-tab-pane label="中心用户" name="first">-->
<!--        <existed-account v-if="selected == 0"></existed-account>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="院校用户" name="second">-->
<!--        <existed-account-school v-if="selected == 1"></existed-account-school>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
    <existed-account></existed-account>
  </div>


</template>

<script>
import ExistedAccount from "@/components/authority/ExistedAccount.vue";
import ExistedAccountSchool from "@/components/authority/ExistedAccountSchool.vue";
export default {
  data() {
    return {
      activeName: 'first',
      roles: [],
      selected:0
    }
  },
  created() {
    // this.getPreviewRole();
  },
  methods: {
    getPreviewRole() {
      this.roles = [{
        description : "角色权限列表",
        roleId : 7}];
      // this.$api.authority.getPreviewRole()
      //     .then(res => {
      //         if (res.data.code == 200) {
      //             this.roles = res.data.data;
      //             console.log(this.roles);
      //             // this.activeName = this.roles[0].description;
      //         }
      //     }).catch(err => {
      //         this.$message.warning('服务器维护');
      //     })
    },
    goBack() {
      this.$router.push({
        name: 'strategy'
      })
    },
    tabClickHandle(tab,event){
      console.log(tab.index);
      this.selected = tab.index
    },
    // changeActiveName1(){
    //     this.selected = 1;
    //     console.log(99999999999)
    // },
    // changeActiveName2(){
    //     this.selected = 2;
    //     console.log(1234143)
    // }
  },
  components: {
    ExistedAccount,
    ExistedAccountSchool
  }
}
</script>

<style scoped>
.el-tabs_self {
  margin-top: -15px;
}

.authBox .el-card__body {
  height: calc(100vh - 300px);
  overflow: auto;
}
</style>

<style>
.margin20.el-divider--horizontal {
  margin: 20px 0px;
}

.selectedAuth .el-card__body {
  padding: 0px;
  padding-bottom: 40px;
  overflow: auto;
}

.authBox .el-card__body {
  height: calc(100vh - 350px);
  overflow: auto;
}
</style>